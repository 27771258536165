@font-face {
  font-family: "Anton";
  src: local("Anton-Regular"), url(./fonts/Anton-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./fonts/OpenSans-VariableFont_wdth.ttf) format("truetype");
}
body {
  background-color: #000000;
  overflow: hidden;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.Background-overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #00000000 0%, #000000 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.Background-tint {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--unnamed-color-ed1c24) 0% 0% no-repeat padding-box;
  background: #ed1c24 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  opacity: 1;
}

.Text-white {
  color: #ffffff;
}

.Text-yellow {
  color: #ffec00;
}

.Text-orange {
  color: #ffaa01;
}

.Text-red {
  color: #ed1c24;
}

.Text-black {
  color: #000000;
}

.Background-black {
  background-color: #000000;
}

.Background-yellow {
  background-color: #ffdb01;
}

.Background-white {
  background-color: #ffffff;
}

.Text-style-title {
  font: normal normal normal 100px/120px Anton;
  text-transform: uppercase;
  white-space: pre-line;
}

.Text-style-subtitle {
  font: normal normal normal 50px/80px Anton;
  text-transform: uppercase;
  white-space: pre-line;
}

.Loading-text {
  font: normal normal 400 26px/32px Anton;
}

.Time-text {
  font: normal normal normal 60px/82px Anton;
  text-transform: uppercase;
}

.Time-text-simple {
  font: normal normal 600 44px/76px Open Sans;
}

.Time-text-small {
  font: normal normal 600 35px/63px Open Sans;
}
.Time-text-very-small {
  font: normal normal 600 19px/19px Open Sans;
}

.Team-title {
  font: normal normal normal 60px/82px Anton;
  text-transform: uppercase;
  text-align: center;
}

.Plus-text {
  font: normal normal 800 35px/63px Anton;
}

.Info-text {
  font: normal normal normal 50px/80px Anton;
  text-transform: uppercase;
}

.Text-shadow {
  text-shadow: 0px 3px 23px #00000054;
}

.Odd-smallest {
  height: 54px;
  font: normal normal 800 30px/54px Open Sans;
}

.Odd-smallest svg {
  /* width: 30px; */
  /* display: inline-block; */
  height: 24px;
}

.Odd-smallest .Name {
  height: 54px;
  /* min-width: 94px; */
  font: normal normal 800 30px/54px Open Sans;
  padding: 0px 12px;
}

.Odd-smallest .Prefix {
  height: 54px;
  font: italic normal bold 30px/54px Open Sans;
  padding: 0px 12px;
}

.Odd-small {
  height: 63px;
}

.Odd-small svg {
  width: 35px;
  height: 35px;
}

.Odd-small .Name {
  font: normal normal normal 35px/63px Anton;
  padding: 0 32px;
}

.Odd-small .Title {
  font: normal normal normal 35px/63px Anton;
  top: -35px;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
}

.Odd-small .Exponent {
  font: normal normal normal 35px/63px Anton;
  top: -35px;
  right: -25%;
}

.Odd-small .Prefix {
  font: normal normal normal 35px/63px Anton;
  padding: 0 24px;
}

.Odd-small .Suffix,
.Odd-small .Suffix > img {
  width: 35px;
  height: 35px;
}

.Odd-normal {
  height: 76px;
}

.Odd-normal svg {
  width: 44px;
  height: 44px;
}

.Odd-normal .Name {
  font: normal normal normal 44px/76px Anton;
  padding: 0 32px;
}

.Odd-normal .Title {
  font: normal normal normal 44px/76px Anton;
  top: -44px;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
}

.Odd-normal .Exponent {
  font: normal normal normal 36px/76px Anton;
  top: -44px;
  right: -25%;
}

.Odd-normal .Prefix {
  font: normal normal normal 44px/76px Anton;
  padding: 0 24px;
}

.Odd-normal .Suffix,
.Odd-normal .Suffix > img {
  width: 44px;
  height: 44px;
}

.Odd-medium {
  height: 98px;
}

.Odd-medium svg {
  width: 64px;
  height: 64px;
}

.Odd-medium .Name {
  font: normal normal normal 60px/98px Anton;
  padding: 0 32px;
}

.Odd-medium .Title {
  font: normal normal normal 60px/98px Anton;
  top: -64px;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
}

.Odd-medium .Exponent {
  font: normal normal normal 60px/98px Anton;
  top: -64px;
  right: -25%;
}

.Odd-medium .Prefix {
  font: normal normal normal 60px/98px Anton;
  padding: 0 24px;
}

.Odd-medium .Suffix,
.Odd-medium .Suffix > img {
  width: 64px;
  height: 64px;
}

.Odd-large {
  height: 120px;
}

.Odd-large svg {
  width: 80px;
  height: 80px;
}

.Odd-large .Name {
  font: normal normal normal 70px/120px Anton;
  padding: 0 64px;
}

.Odd-large .Title {
  font: normal normal normal 80px/120px Anton;
  top: -80px;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
}

.Odd-large .Exponent {
  font: normal normal normal 80px/120px Anton;
  top: -80px;
  right: -25%;
}

.Odd-large .Prefix {
  font: normal normal normal 80px/120px Anton;
  padding: 0 24px;
}

.Odd-large .Suffix,
.Odd-large .Suffix > img {
  width: 80px;
  height: 80px;
}

.Odd-extralarge {
  height: 200px;
}

.Odd-extralarge svg {
  width: 98px;
  height: 98px;
}

.Odd-extralarge .Name {
  font: normal normal normal 98px/200px Anton;
  padding: 0 64px;
}

.Odd-extralarge .Title {
  font: normal normal normal 116px/200px Anton;
  top: -112px;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
}

.Odd-extralarge .Exponent {
  font: normal normal normal 98px/200px Anton;
  top: -98px;
  right: -25%;
}

.Odd-extralarge .Prefix {
  font: normal normal normal 98px/200px Anton;
  padding: 0 24px;
}

.Odd-extralarge .Suffix,
.Odd-extralarge .Suffix > img {
  width: 98px;
  height: 98px;
}

.Match-normal {
  height: 76px;
}

.Match-normal > .Title {
  font: normal normal normal 44px/76px Anton;
  text-transform: uppercase;
}

.Match-normal > .Index {
  width: 76px;
  height: 76px;
  font: normal normal normal 44px/76px Anton;
  text-transform: uppercase;
}

.Match-small {
  height: 63px;
}

.Match-small > .Title {
  font: normal normal normal 35px/63px Anton;
  text-transform: uppercase;
}

.Match-small > .Suffix {
  font: normal normal normal 35px/63px Anton;
}

.Match-small > .Index {
  width: 63px;
  height: 63px;
  font: normal normal normal 35px/63px Anton;
  text-transform: uppercase;
}

.Match-smallest {
  height: 54px;
}

.Match-smallest > .Title {
  font: normal normal normal 30px/54px Anton;
  text-transform: uppercase;
}

.Match-smallest > .Suffix {
  font: normal normal normal 30px/54px Anton;
}

.Match-smallest > .Index {
  width: 54px;
  height: 54px;
  font: normal normal normal 30px/54px Anton;
  text-transform: uppercase;
}

.Dashed-border {
  border: 0 none;
  border-top: 2px dashed white;
  background: none;
  height: 0;
}

.Fade-in {
  -webkit-animation: fade 0.3s ease-in-out;
  -moz-animation: fade 0.3s ease-in-out;
  animation: fade 0.3s ease-in-out;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
